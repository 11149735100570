document.addEventListener('DOMContentLoaded', () => {

  const searchResults = document.querySelector('#searchResults')

  if (searchResults) {
    const options = {
      root: document.querySelector('#searchResults'),
      rootMargin: '100px',
    }

    var intersectionObserver = new IntersectionObserver(entries => processIntersectionEntries(entries), options)

    function processIntersectionEntries(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log('intersected')

          const loadBtn =  document.querySelector('a#searchLoadMore')
          if (loadBtn) {
            // TODO: better to make direct call with fetch
            loadBtn.click()
          }

        }
      })
    }

    setTimeout(() => {
      intersectionObserver.observe(document.querySelector('#js-intersection-target'))
    }, 2000)


  }

})
